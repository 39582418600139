<template>
    <div style="background: #FF2D2D;height: 100%">
        <div class="container">
            <div class="cash-out">
                <div class="price">您有<span>{{ urlParams.amount }}</span>元待提现</div>
                <div class="tips">先验证再提现哦</div>
                <div class="input-box dz-flex dz-aic" style="margin-top: 34px">
                    <img class="phone" src="@/assets/images/cashOut/phone.png" />
                    <input v-model="mobile" placeholder="请输入手机号" type="tel" maxlength="11" />
                </div>
                <div class="input-box dz-flex dz-aic">
                    <img class="msg" src="@/assets/images/cashOut/message.png" />
                    <input v-model="verifiCode" placeholder="请输入验证码" type="tel" maxlength="6" />
                    <div class="code" @click="getCode">{{ promptTxt }}</div>
                </div>
                <button class="cash-out-btn" @click="update">立即提现</button>
            </div>
        </div>
    </div>
</template>

<script>
import { Toast } from 'vant';

export default {
    name: 'Index',
    data() {
        return {
            mobile: '',
            verifiCode: '',
            time: 60,
            promptTxt: '发送验证码',
            urlParams: {}
        };
    },
    mounted() {
        this.urlParams = this.$route.query;
    },
    methods: {
        // 验证码倒计时
        timer() {
            if (this.time > 1) {
                this.time--;
                this.promptTxt = this.time + '后重新获取';
                this.outTimer = setTimeout(this.timer, 1000);
            } else {
                this.outTimer && clearTimeout(this.outTimer);
                this.time = 60;
                this.promptTxt = '发送验证码';
            }
        },

        getCode() {
            const { mobile } = this;

            if (!mobile) {
                Toast('请输入手机号');
                return;
            }
            if (!this.isMobile(mobile)) {
                Toast('请输入正确的手机号码');
                return;
            }

            if (this.time !== 60) return;

            this.$ajax.post('/wx/pay/sendSms', {
                mobile: this.mobile,
                unionId: this.urlParams.ptu
            }).then(res => {
                Toast('手机验证码发送成功');
                this.timer();
            });
        },

        isMobile(value) {
            var exp = /^1\d{10}$/;
            return exp.test(value);
        },

        update() {
            const { mobile, verifiCode, urlParams } = this;
            const { ptu: ptUnionId, dzo: dzOpenId } = urlParams;
            if (!mobile) {
                Toast('请输入手机号');
                return;
            }
            if (!verifiCode) {
                Toast('请输入验证码');
                return;
            }
            this.$ajax.post('/wx/pay/entPay', {
                verifiCode,
                ptUnionId,
                dzOpenId
            }).then(res => {
                Toast('提现成功');
                this.mobile = '';
                this.verifiCode = '';
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  width: 750px;
  height: 1236px;
  background: url("~@/assets/images/cashOut/cashOut-bg.png");
  background-size: cover;

  .cash-out {
    position: absolute;
    text-align: center;
    left: 75px;
    top: 354px;
    width: 600px;
    height: 580px;
    background-color: #ffffff;
    box-shadow: 10px 12px 16px 0px rgba(220, 0, 0, 0.5),
    inset 0px 0px 12px 0px rgba(255, 73, 102, 0.2);
    border-radius: 24px;
  }

  .price {
    margin-top: 60px;
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
    color: #000000;
    text-align: center;

    span {
      font-size: 42px;
      color: #ff1919;
    }
  }

  .tips {
    font-size: 26px;
    line-height: 36px;
    letter-spacing: 3px;
    color: #999999;
    margin-top: 25px;
    text-align: center;

    &:before {
      content: '';
      display: inline-block;
      width: 48px;
      height: 2px;
      background-image: linear-gradient(to right, #fff, #999);
      vertical-align: middle;
      margin-right: 17px;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 48px;
      height: 2px;
      background-image: linear-gradient(to right, #999, #fff);
      vertical-align: middle;
      margin-left: 17px;
    }
  }

  .input-box {
    position: relative;
    width: 500px;
    height: 96px;
    border-radius: 2px;
    margin: 0 auto;
    border-bottom: 2px solid #ededed;
  }

  .phone {
    width: 28px;
    height: 32px;
  }

  .msg {
    width: 28px;
    height: 30px;
  }

  input {
    margin-left: 23px;
    font-size: 30px;
  }

  .cash-out-btn {
    margin-top: 60px;
    width: 460px;
    height: 88px;
    background-image: linear-gradient(0deg,
        #ff2626 0%,
        #ff7f7f 100%),
    linear-gradient(
            #ff2626,
            #ff2626);
    background-blend-mode: normal,
    normal;
    border-radius: 44px;
    font-size: 32px;
    line-height: 88px;
    color: #ffffff;
  }

  .code {
    text-align: right;
    position: absolute;
    right: 0;
    width: 200px;
    height: 30px;
    font-size: 30px;
    line-height: 36px;
    color: #386db1;
  }
}
</style>
